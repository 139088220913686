import request from '@/auth/jwt/request'

/**
 * @description 话术管理 -- 话术管理列表
 * @param {Object} param params {Object} 传值参数
 */
export const talksListApi = params => { return request('words-library/list', 'get', params) }

/**
 * @description 话术管理 -- 话术分类下拉
 * @param {Object} param params {Object} 传值参数
 */
export const talksCateSelectApi = () => { return request('words-category/select', 'get', null) }

/**
 * @description 话术管理 -- 话术分类下拉
 * @param {Object} param params {Object} 传值参数
 */
export const talksProjetSelectApi = () => { return request('project/select', 'get', null) }

/**
 * @description 话术管理 -- 增加修改话术
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const talksSaveApi = data => { return request('words-library/save', 'post', {}, data) }

/**
 * @description 话术管理 -- 话术状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const talksStatusApi = params => { return request('words-library/status', 'get', params) }

/**
 * @description 话术管理 -- 话术详情
 * @param {Object} param params {Object} 传值参数
 */
export const talksInfoApi = params => { return request('words-library/info', 'get', params) }
/**
 * @description 话术管理 -- 删除话术
 * { id = x }
 */
export const talksDeleteApi = data => { return request('words-library/delete', 'delete', data) }